/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-deprecated */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { has, isEmpty, isNil, debounce, isEqual, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import ReactRouterPropTypes from 'react-router-prop-types';
import legalImage from '@brand/images/legal/legal.jpg';
import classNames from 'classnames';

import { setPageTitle, showToast, setDirtyForm } from '../layout/layout.actions';
import { uploadFiles } from '../../utilities/upload';
import {
  GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
  GET_MEDICAL_HISTORY_SUCCESS,
  SAVE_PAYMENT_METHOD_SUCCESS,
  UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS,
  UPDATE_MEDICAL_HISTORY_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  getDependentMedicalHistory,
  getMedicalHistory,
  getPaymentMethods,
  savePaymentMethod,
  updateDependentMedicalHistory,
  updateMedicalHistory,
  updateProfile,
  getProfile,
  GET_PROFILE_SUCCESS,
  getInsuranceInfo,
  saveInsuranceInfo,
  SAVE_INSURANCE_INFO_SUCCESS,
  GET_INSURANCE_INFO_SUCCESS,
} from '../profile/profile.actions';
import {
  GET_PREVIOUS_VISITS_SUCCESS,
  SAVE_PHARMACY_SUCCESS,
  START_VISIT_SUCCESS,
  getCurrentVisit,
  getPharmacyDetails,
  getPreviousVisits,
  getPricing,
  resetPharmacies,
  savePharmacy,
  setPricingCodes,
  startVisit,
  GET_PRICING_SUCCESS,
  clearPharmacy,
} from './visit.actions';
import {
  getFacilityPatientMedicalHistory,
  GET_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS,
  updateFacilityPatient,
  UPDATE_FACILITY_PATIENT_SUCCESS,
  setSelectedFacility,
  UPDATE_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS,
  updateFacilityPatientMedicalHistory,
  clearPatients,
  setFacilityPatientSearchText,
} from '../facility/facility.actions';
import { updateDependent, UPDATE_DEPENDENT_SUCCESS } from '../dependents/dependent.actions';
import { getCurrentPosition } from '../location/location.actions';
import {
  createInsurancePayload,
  formatInsuranceInfo,
  MAX_INSURANCE_ATTACHMENTS,
} from '../../utilities/insuranceUtils';
import PersonalInfoForm from '../patient/forms/patientProfileForm.component';
import InfoReviewForm from '../patient/infoReview/visitInfoReviewForm.component';
import VisitInfoForm from './forms/visitInfoForm.component';
import VisitLegalTermsForm from './forms/visitLegalTermsForm.component';
import PermissionsForm from './forms/visitPermissionsForm.component';
import VisitPaymentInfoForm from './forms/visitPaymentInfoForm.component';
import MedicalHistoryForm from '../patient/forms/patientMedicalHistoryForm.component';
import PharmacyForm from '../patient/forms/pharmacyForm.component';
import InsuranceForm from './forms/visitInsuranceForm.component';

import TOAST_TYPES from '../../types/toastTypes';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import accountTypes from '../../types/accountTypes';
import {
  browserPermissionTypes,
  browserPermissionStatus,
} from '../../types/browserPermissionTypes';
import paymentTypes from '../../types/paymentTypes';
import { lessThan12YearsAgo, DATE_INPUT_DATE_FORMAT } from '../../utilities/fieldValidation';
import Languages from '../language/languages';
import { logEvent } from '../../utilities/googleAnalytics';
import UIVars from '../../styles/ui-vars';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContentTypes';
import { getMarketingContent } from '../../utilities/entranceUtils';

import { config as brandConfig } from '@brand';

const { entranceId } = brandConfig;

const initialVisitState = {
  chiefComplaint: '',
  attachments: [],
  couponCode: '',
  groupCode: '',
  groupIdentifier: '',
  legalConsent: [],
  patientId: '',
  pricing: {},
  paymentMethod: {},
  location: {
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: '',
    addressZip: '',
  },
  mobileNumber: '',
};

// start with default status of PROMPT
// will be updated on query in permission screen
const requiredPermissions = [
  {
    type: browserPermissionTypes.CAMERA,
    status: browserPermissionStatus.PROMPT,
  },
  {
    type: browserPermissionTypes.MICROPHONE,
    status: browserPermissionStatus.PROMPT,
  },
];

class VisitContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardBrand: null,
      cardNumber: null,
      currentStepIndex: 0,
      insuranceInfo: {},
      isUploadingFiles: false,
      medicalHistory: {},
      medicalHistoryResponses: {},
      permissions: requiredPermissions,
      profile: {},
      selectedLegalTerms: [],
      selectedPaymentMethod: paymentTypes.CREDIT_CARD, // force selection of credit card
      selectedPharmacy: null,
      stepErrors: [],
      steps: [],
      stripeToken: null,
      visit: initialVisitState,
    };
  }

  componentWillReceiveProps(nextProps) {
    const updateState = { ...this.state };

    if (!isEmpty(nextProps.paymentMethods)) {
      updateState.selectedPaymentMethod = nextProps.paymentMethods[0];
      updateState.cardNumber = nextProps.paymentMethods[0].last4;
      updateState.cardBrand = nextProps.paymentMethods[0].brand;
    }

    if (has(nextProps, 'pharmacy')) {
      updateState.selectedPharmacy = nextProps.pharmacy;
    }

    this.setState(updateState);
  }

  async componentDidMount() {
    this.props.setPageTitle(
      Languages[this.props.selectedLanguageKey].strings.pageTitles.startVisit,
    );

    await this.initPricing();

    if (!isNil(this.props.selectedFacility)) {
      await this.initFacility();
      this.props.setFacilityPatientSearchText('');
      this.props.clearPatients();
    }

    await this.props.getPaymentMethods();
    this.props.getCurrentPosition();

    // clear pharmacy state when loading
    // prevents stale data from being persisted in redux
    this.props.resetPharmacies();
    this.props.clearPharmacy();

    // INITIALIZE PATIENT PROFILE AND PHARMACY
    if (has(this.props.location.state, 'profile')) {
      // FACILITATED PATIENT
      const patientProfile = this.props.location.state.profile;
      patientProfile.accountType = accountTypes.FACILITY_PATIENT;

      // INIT PHARMACY
      if (patientProfile.pharmacyDoseSpotId) {
        await this.props.getPharmacyDetails(patientProfile.id);
      }

      // INIT INSURANCE INFO
      const insuranceResult = await this.props.getInsuranceInfo(patientProfile.id);

      const medHistoryResult = await this.props.getFacilityPatientMedicalHistory(patientProfile.id);
      const previousVisitsResult = await this.props.getPreviousVisits(patientProfile.id);

      if (
        medHistoryResult.type === GET_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS &&
        previousVisitsResult.type === GET_PREVIOUS_VISITS_SUCCESS
      ) {
        const isInitialVisit = previousVisitsResult.response.data.length === 0;
        const isIncompleteProfile =
          has(patientProfile, 'incomplete') && patientProfile.incomplete === true;

        this.setState(
          {
            insuranceInfo: formatInsuranceInfo(insuranceResult.response, patientProfile),
            medicalHistory: medHistoryResult.response,
            medicalHistoryResponses: this.getInitialMedicalResponses(medHistoryResult.response),
            profile: patientProfile,
          },
          () => {
            // get steps after initializing data and checking prior visits
            this.setState({
              steps: this.getVisitSteps(isInitialVisit || isIncompleteProfile),
            });
          },
        );
      }
    } else if (
      has(this.props, 'match.params.patientId') &&
      has(this.props, 'profile') &&
      !isEmpty(this.props.profile)
    ) {
      // ACCOUNT HOLDER
      if (this.props.profile.id === this.props.match.params.patientId) {
        const patientProfile = { ...this.props.profile };
        patientProfile.accountType = accountTypes.ACCOUNT_HOLDER;

        // INIT PHARMACY
        if (patientProfile.pharmacyDoseSpotId) {
          await this.props.getPharmacyDetails(patientProfile.id);
        }

        // INIT INSURANCE INFO
        const insuranceResult = await this.props.getInsuranceInfo(patientProfile.id);

        const medHistoryResult = await this.props.getMedicalHistory();
        const previousVisitsResult = await this.props.getPreviousVisits(patientProfile.id);

        if (
          medHistoryResult.type === GET_MEDICAL_HISTORY_SUCCESS &&
          previousVisitsResult.type === GET_PREVIOUS_VISITS_SUCCESS
        ) {
          const isInitialVisit = previousVisitsResult.response.data.length === 0;
          const isIncompleteProfile =
            has(patientProfile, 'incomplete') && patientProfile.incomplete === true;

          this.setState(
            {
              insuranceInfo: formatInsuranceInfo(insuranceResult.response, patientProfile),
              medicalHistory: medHistoryResult.response,
              medicalHistoryResponses: this.getInitialMedicalResponses(medHistoryResult.response),
              profile: patientProfile,
            },
            () => {
              // get steps after initializing data and checking prior visits
              this.setState({
                steps: this.getVisitSteps(isInitialVisit || isIncompleteProfile),
              });
            },
          );
        } else {
          this.handleNavigateHome();
        }
      } else {
        // DEPENDENT
        const foundProfile = this.props.profile.dependents.find(
          (dep) => dep.id === this.props.match.params.patientId,
        );
        if (!isEmpty(foundProfile)) {
          const patientProfile = { ...foundProfile };
          patientProfile.accountType = accountTypes.CHILD;

          // INIT PHARMACY
          if (patientProfile.pharmacyDoseSpotId) {
            await this.props.getPharmacyDetails(patientProfile.id);
          } else if (this.props.profile && this.props.profile.pharmacyDoseSpotId) {
            // if pharmacy not available on a dependent, load from account holder profile if available
            await this.props.getPharmacyDetails(this.props.profile.id);
          }

          // INIT INSURANCE INFO
          const insuranceResult = await this.props.getInsuranceInfo(patientProfile.id);

          const medHistoryResult = await this.props.getDependentMedicalHistory(patientProfile.id);
          const previousVisitsResult = await this.props.getPreviousVisits(patientProfile.id);

          if (
            medHistoryResult.type === GET_DEPENDENT_MEDICAL_HISTORY_SUCCESS &&
            previousVisitsResult.type === GET_PREVIOUS_VISITS_SUCCESS
          ) {
            const isInitialVisit = previousVisitsResult.response.data.length === 0;
            const isIncompleteProfile =
              has(patientProfile, 'incomplete') && patientProfile.incomplete === true;

            this.setState(
              {
                insuranceInfo: formatInsuranceInfo(insuranceResult.response, patientProfile),
                medicalHistory: medHistoryResult.response,
                medicalHistoryResponses: this.getInitialMedicalResponses(medHistoryResult.response),
                profile: patientProfile,
              },
              () => {
                // get steps after initializing data and checking prior visits
                this.setState({
                  steps: this.getVisitSteps(isInitialVisit || isIncompleteProfile),
                });
              },
            );
          } else {
            this.handleNavigateHome();
          }
        } else {
          // no profile found
          this.handleNavigateHome();
        }
      }
    }
  }

  // initialize pricing and pricing codes
  // can load from account profile
  initPricing = async () => {
    let { group, identifier, coupon } = this.props.pricingCodes;

    // if pricingData is included in profile but not in props pricingCodes, try and set codes in visit from profile
    // do not load if there's a problem loading codes
    // require at least a group code
    const { profile } = this.props;

    const shouldLoadFromAccount =
      has(profile, 'pricingData.groupCode') && isNil(this.props.pricingCodes.group);

    if (shouldLoadFromAccount) {
      // get group and identifier
      if (!isNil(profile.pricingData.groupCode)) {
        group = profile.pricingData.groupCode;
      }
      if (!isNil(profile.pricingData.groupIdentifier)) {
        identifier = profile.pricingData.groupIdentifier;
      }

      // check for error condition in pricing result
      // if an error message is received, do not load pricing group/id
      const pricingResult = await this.props.getPricing('', group, identifier);
      if (pricingResult.type === GET_PRICING_SUCCESS) {
        if (!has(pricingResult.response, 'price.message')) {
          // save groupCode and groupIdentifier in local visit state and redux
          const updateVisitState = { ...this.state.visit };
          updateVisitState.groupCode = group;
          updateVisitState.groupIdentifier = identifier;
          this.setState({
            visit: updateVisitState,
          });
          await this.props.setPricingCodes('', group, identifier);
        } else {
          // ERROR MESSAGE
          // can happen if someone is no longer part of a pricing group
          // reset codes
          await this.props.getPricing('', '', '');
          await this.props.setPricingCodes('', '', '');
        }
      }
    } else {
      // initialize codes from props instead of account profile
      await this.props.getPricing(coupon, group, identifier);
      await this.props.setPricingCodes(coupon, group, identifier);
    }
  };

  initFacility = async () => {
    // ensure facility settings are updated in case they changed between page loads
    const result = await this.props.getProfile();
    if (result.type === GET_PROFILE_SUCCESS) {
      if (has(result.response, 'facilities') && !isEmpty(result.response.facilities)) {
        // update selectedFacility from response data
        const foundFacility = result.response.facilities.find(
          (f) => f.id === this.props.selectedFacility.id,
        );
        if (!isNil(foundFacility) && !isEqual(foundFacility, this.props.selectedFacility)) {
          this.props.setSelectedFacility(foundFacility);
        }
      }
    }
  };

  handleSetToken = (token) => {
    logEvent('card_entered', {
      patientId: this.state.profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });
    this.setState({
      stripeToken: token,
      cardBrand: token.card.brand,
      cardNumber: token.card.last4,
    });
  };

  handleSavePaymentMethod = async () => {
    if (!isNil(this.state.stripeToken)) {
      const response = await this.props.savePaymentMethod({
        token: this.state.stripeToken.id,
      });
      // clear stripe token after save
      if (response.type === SAVE_PAYMENT_METHOD_SUCCESS) {
        this.setState({
          currentStepIndex: this.state.currentStepIndex + 1,
          stripeToken: null,
        });
      } else {
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.updatePaymentFailure,
          TOAST_TYPES.ERROR,
          true,
          null,
          response.messages[0],
        );
        return false;
      }
    } else {
      this.setState({
        currentStepIndex: this.state.currentStepIndex + 1,
      });
    }
  };

  // re-fetch insurance info in case of an update from review screen
  handleInsuranceUpdate = async () => {
    const response = await this.props.getInsuranceInfo(this.state.profile.id);

    if (response.type === GET_INSURANCE_INFO_SUCCESS) {
      this.setState({
        insuranceInfo: formatInsuranceInfo(response.response, this.state.profile),
      });
    }
  };

  saveInsurance = async () => {
    const payload = createInsurancePayload(this.state.insuranceInfo);
    const response = await this.props.saveInsuranceInfo(this.state.profile.id, payload);

    if (response.type === SAVE_INSURANCE_INFO_SUCCESS) {
      this.setState({
        currentStepIndex: this.state.currentStepIndex + 1,
      });
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateInsuranceFailure,
        TOAST_TYPES,
        true,
        null,
        response.messages[0],
      );
    }
  };

  // depending on presence of a profile, we will either collect or confirm the profile
  getVisitSteps = (isCollectProfile) => {
    const language = Languages[this.props.selectedLanguageKey].strings;
    const isFacilitatedVisit = !isNil(this.props.selectedFacility);

    const isShowInsurance =
      has(this.props.entrance, 'clientConfig.collectInsurance') &&
      this.props.entrance.clientConfig.collectInsurance === true &&
      !isFacilitatedVisit;

    let collectProfileSteps = [
      {
        id: 'PATIENT_INFO',
        label: language.visitPatientInfoLabel,
        heading: language.visitPatientInfoHeading,
        subHeading: language.visitPatientInfoSubHeading,
        nextAction: this.handleSaveProfile,
        nextText: language.buttonText.save,
        getContent: () => (
          <PersonalInfoForm
            profile={this.state.profile}
            handleValidationChange={this.handleValidationChange('PATIENT_INFO')}
            handleChange={this.handleProfileFieldChange}
            forceValidation
            selectedLanguageKey={this.props.selectedLanguageKey}
          />
        ),
      },
      {
        id: 'TODAYS_VISIT',
        heading: language.visitTodaysVisitHeading,
        label: language.visitToadysVisitLabel,
        getContent: () => (
          <VisitInfoForm
            visit={this.state.visit}
            isUploadingFiles={this.state.isUploadingFiles}
            selectedLanguageKey={this.props.selectedLanguageKey}
            handleAddAttachments={this.handleAddVisitAttachments}
            handleChange={this.handleVisitFieldChange}
            handleRemoveAttachment={this.handleRemoveVisitAttachment}
            handleValidationChange={this.handleValidationChange('TODAYS_VISIT')}
          />
        ),
      },
      {
        id: 'INSURANCE_INFO',
        heading: language.visitInsuranceHeading,
        nextAction: this.saveInsurance,
        nextText: language.buttonText.save,
        getContent: () => (
          <InsuranceForm
            insuranceInfo={this.state.insuranceInfo}
            isUploadingFiles={this.state.isUploadingFiles}
            profile={this.state.profile}
            selectedLanguageKey={this.props.selectedLanguageKey}
            handleAddAttachment={this.handleAddInsuranceAttachment}
            handleRemoveAttachment={this.handleRemoveInsuranceAttachment}
            handleValidationChange={this.handleValidationChange('INSURANCE_INFO')}
            onFieldChange={this.handleInsuranceFieldChange}
          />
        ),
      },
      {
        id: 'PAYMENT_INFO',
        label: language.visitPaymentInfoLabel,
        heading: language.visitPaymentInfoHeading,
        nextAction: this.handleSavePaymentMethod,
        nextText: language.buttonText.save,
        getContent: () => (
          <VisitPaymentInfoForm
            cardBrand={this.state.cardBrand}
            cardNumber={this.state.cardNumber}
            couponCode={this.state.visit.couponCode}
            groupCode={this.state.visit.groupCode}
            groupIdentifier={this.state.visit.groupIdentifier}
            handleChange={this.handlePaymentFieldChange}
            handleSelectPaymentMethod={this.handleSelectPaymentMethod}
            handleSetToken={this.handleSetToken}
            pricing={this.props.pricing}
            selectedPaymentMethod={this.state.selectedPaymentMethod}
          />
        ),
      },
      {
        id: 'MED_HISTORY',
        label: language.visitMedicalHistoryLabel,
        heading: language.visitMedicalHistoryHeading,
        nextAction: this.handleSaveMedicalHistory,
        nextText: language.buttonText.save,
        getContent: () => (
          <MedicalHistoryForm
            visit={this.state.visit}
            medicalHistoryResponses={this.state.medicalHistoryResponses}
            medicalHistory={this.state.medicalHistory}
            handleMedicalHistoryResponse={this.handleMedicalHistoryResponse}
            handleSelectFieldChange={this.handleMedicalSelectFieldChange}
            isLoading={this.props.isLoadingMedicalHistory || !this.state.isInitialized}
          />
        ),
      },
      {
        id: 'PHARMACY',
        label: language.visitPharmacyLabel,
        heading: language.visitPharmacyHeading,
        hideNext: true, // only way to proceed is to select a pharmacy
        nextAction: this.handleSavePharmacy,
        nextText: language.buttonText.save,
        getContent: () => (
          <PharmacyForm
            onSelectPharmacy={this.handleSelectPharmacy}
            selectedPharmacy={this.state.selectedPharmacy}
          />
        ),
      },
      {
        id: 'VISIT_TERMS',
        label: language.visitTermsLabel,
        heading: language.visitTermsHeading,
        getContent: () => (
          <VisitLegalTermsForm
            selectedLegalTerms={this.state.selectedLegalTerms}
            toggleLegalAccepted={this.toggleLegalAccepted}
          />
        ),
      },
      {
        id: 'PERMISSIONS',
        label: language.visitPermissionsLabel,
        nextAction: this.startVisit,
        nextText: language.buttonText.startVisit,
        getContent: () => (
          <PermissionsForm
            handleValidationChange={this.handleValidationChange('PERMISSIONS')}
            handleChange={this.handleVisitFieldChange}
            onPermissionChange={this.handlePermissionChange}
            onLocationChange={this.handleVisitLocationChange}
            permissions={this.state.permissions}
            grantedPermissions={this.state.grantedPermissions}
            mobileNumber={this.state.visit.mobileNumber}
            location={this.state.visit.location}
          />
        ),
      },
    ];

    let confirmProfileSteps = [
      {
        id: 'CONFIRM_INFO',
        label: language.visitConfirmInfoLabel,
        heading: language.visitConfirmInfoHeading,
        getContent: () => (
          <InfoReviewForm
            entrance={this.props.entrance}
            insuranceInfo={this.state.insuranceInfo}
            isLoadingInsuranceInfo={this.props.isLoadingInsuranceInfo}
            isVisitInitialized={this.state.isInitialized}
            medicalHistory={this.state.medicalHistory}
            medicalHistoryResponses={this.state.medicalHistoryResponses}
            paymentMethods={this.props.paymentMethods}
            pricing={this.props.pricing}
            profile={this.state.profile}
            selectedFacility={this.props.selectedFacility}
            selectedLanguageKey={this.props.selectedLanguageKey}
            selectedPharmacy={this.state.selectedPharmacy}
            handleInsuranceUpdate={this.handleInsuranceUpdate}
          />
        ),
      },
      {
        id: 'TODAYS_VISIT',
        label: language.visitTodaysVisitLabel,
        heading: language.visitTodaysVisitHeading,
        getContent: () => (
          <VisitInfoForm
            visit={this.state.visit}
            handleChange={this.handleVisitFieldChange}
            handleValidationChange={this.handleValidationChange('TODAYS_VISIT')}
            handleAddAttachments={this.handleAddVisitAttachments}
            handleRemoveAttachment={this.handleRemoveVisitAttachment}
            isUploadingFiles={this.state.isUploadingFiles}
            selectedLanguageKey={this.props.selectedLanguageKey}
          />
        ),
      },
      {
        id: 'VISIT_TERMS',
        label: language.visitTermsLabel,
        heading: language.visitTermsHeading,
        getContent: () => (
          <VisitLegalTermsForm
            selectedLegalTerms={this.state.selectedLegalTerms}
            toggleLegalAccepted={this.toggleLegalAccepted}
          />
        ),
      },
      {
        id: 'PERMISSIONS',
        label: language.visitPermissionsLabel,
        nextText: language.buttonText.startVisit,
        nextAction: this.startVisit,
        getContent: () => (
          <PermissionsForm
            handleValidationChange={this.handleValidationChange('PERMISSIONS')}
            handleChange={this.handleVisitFieldChange}
            onPermissionChange={this.handlePermissionChange}
            onLocationChange={this.handleVisitLocationChange}
            permissions={this.state.permissions}
            mobileNumber={this.state.visit.mobileNumber}
            location={this.state.visit.location}
            grantedPermissions={this.state.grantedPermissions}
          />
        ),
      },
    ];

    // if facilitated, filter out payment screen
    // depending on facility settings, also filter legal terms screen
    if (isFacilitatedVisit) {
      const { selectedFacility } = this.props;
      let shouldUpdateState = false;
      let stateUpdates = {};

      // remove payment info from profile collection steps
      collectProfileSteps = collectProfileSteps.filter((s) => s.id !== 'PAYMENT_INFO');

      // remove legal agreement if specified in facility settings
      if (
        has(selectedFacility, 'skipLegalAgreement') &&
        selectedFacility.skipLegalAgreement === true
      ) {
        collectProfileSteps = collectProfileSteps.filter((s) => s.id !== 'VISIT_TERMS');
        confirmProfileSteps = confirmProfileSteps.filter((s) => s.id !== 'VISIT_TERMS');
      }

      // remove medical history if specified in facility settings
      if (has(selectedFacility, 'phiVisible') && selectedFacility.phiVisible === false) {
        collectProfileSteps = collectProfileSteps.filter((s) => s.id !== 'MED_HISTORY');
      }

      if (
        has(selectedFacility, 'skipChiefComplaint') &&
        selectedFacility.skipChiefComplaint === true
      ) {
        collectProfileSteps = collectProfileSteps.filter((s) => s.id !== 'TODAYS_VISIT');
        confirmProfileSteps = confirmProfileSteps.filter((s) => s.id !== 'TODAYS_VISIT');
        shouldUpdateState = true;
        stateUpdates = {
          ...stateUpdates,
          chiefComplaint: 'N/A',
        };
      }

      if (
        has(selectedFacility, 'skipPhone') &&
        selectedFacility.skipPhone === true &&
        has(selectedFacility, 'phoneNumber')
      ) {
        shouldUpdateState = true;
        stateUpdates = {
          ...stateUpdates,
          mobileNumber: selectedFacility.phoneNumber,
        };
      }

      if (has(selectedFacility, 'skipAddress') && selectedFacility.skipAddress === true) {
        shouldUpdateState = true;
        stateUpdates = {
          ...stateUpdates,
          location: {
            addressLine1: selectedFacility.addressLine1,
            addressCity: selectedFacility.addressCity,
            addressState: selectedFacility.addressState,
            addressZip: selectedFacility.addressZip,
          },
        };
      }

      if (has(selectedFacility, 'skipPharmacy') && selectedFacility.skipPharmacy === true) {
        collectProfileSteps = collectProfileSteps.filter((s) => s.id !== 'PHARMACY');
      }

      if (shouldUpdateState) {
        this.setState({
          visit: {
            ...this.state.visit,
            ...stateUpdates,
          },
        });
      }
    }

    // if entrance specifies no insurance collection, remove collection steps
    if (!isShowInsurance) {
      collectProfileSteps = collectProfileSteps.filter((s) => s.id !== 'INSURANCE_INFO');
    }

    return isCollectProfile ? collectProfileSteps : confirmProfileSteps;
  };

  handleNavigateHome = () => {
    this.props.history.push('/');
  };

  handleBack = () => {
    if (this.state.currentStepIndex === 0) {
      this.handleNavigateHome();
    } else {
      this.setState({
        currentStepIndex: this.state.currentStepIndex - 1,
        stepErrors: [], // reset step errors when moving away from step
      });
    }
  };

  handleNext = () => {
    if (this.state.steps[this.state.currentStepIndex].id === 'TODAYS_VISIT') {
      logEvent('cheif_complaint', {
        patientId: this.state.profile.id,
        entranceId,
        accountId: this.props.profile.id,
      });
    }
    this.setState({
      currentStepIndex: this.state.currentStepIndex + 1,
    });
  };

  handleSavePharmacy = async () => {
    const { profile, selectedPharmacy, currentStepIndex } = this.state;

    const result = await this.props.savePharmacy(profile.id, selectedPharmacy.PharmacyId);

    if (result.type === SAVE_PHARMACY_SUCCESS) {
      await this.props.getPharmacyDetails(profile.id);
      this.setState({
        currentStepIndex: currentStepIndex + 1,
      });
    }
  };

  handleSelectPharmacy = (selectedPharmacy = null) => {
    logEvent('phar_search', {
      patientId: this.state.profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });
    this.setState({ selectedPharmacy });
  };

  handleProfileFieldChange = (field) => (update) => {
    const updateProfileState = { ...this.state.profile };

    // see if update is an event, if not it is a value already
    updateProfileState[field] =
      !isNil(update) && has(update.target, 'value') ? update.target.value : update;

    this.setState({
      profile: {
        ...this.state.profile,
        ...updateProfileState,
      },
    });
  };

  handleVisitLocationChange = (field) => (value) => {
    const updateLocation = { ...this.state.visit.location };
    updateLocation[field] = value;
    this.setState({
      visit: {
        ...this.state.visit,
        location: updateLocation,
      },
    });
    logEvent('update_location', {
      patientId: this.state.profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });
  };

  handleAddInsuranceAttachment = (attachments) => {
    const numOfAttachments = this.state.insuranceInfo.attachments.length + attachments.length;

    if (numOfAttachments > MAX_INSURANCE_ATTACHMENTS) {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages
          .insuranceAttachmentLimit,
        TOAST_TYPES.ERROR,
      );
      return;
    }

    this.setState({
      isUploadingFiles: true,
    });

    uploadFiles(
      attachments,
      (response) => {
        this.setState((prevState) => ({
          isUploadingFiles: false,
          insuranceInfo: {
            ...prevState.insuranceInfo,
            attachments: [].concat(prevState.insuranceInfo.attachments, response.data),
          },
        }));
      },
      (err) => {
        console.log(`error during image upload: ${err}`);
        this.setState({ isUploadingFiles: false });
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.uploadImageFailure,
          TOAST_TYPES.ERROR,
        );
      },
      (pct) => console.log('upload progress: ', pct),
      () => console.log('upload cancelled'),
    );
  };

  handleRemoveInsuranceAttachment = (index) => {
    const attachments = [].concat(this.state.insuranceInfo.attachments);
    attachments.splice(index, 1);

    this.setState((prevState) => ({
      insuranceInfo: {
        ...prevState.insuranceInfo,
        attachments,
      },
    }));
  };

  // immediately add attachment to visit state
  // do background upload of image
  // block next navigation until all image is uploaded
  handleAddVisitAttachments = (attachments) => {
    const updateAttachments = [].concat(this.state.visit.attachments);
    updateAttachments.concat(attachments);
    this.setState({
      visit: {
        ...this.state.visit,
        attachments: updateAttachments,
      },
      isUploadingFiles: true,
    });

    uploadFiles(
      attachments,
      (response) => {
        logEvent('upload_photo', {
          patientId: this.state.profile.id,
          entranceId,
          accountId: this.props.profile.id,
        });
        const updateAttachmentsState = []
          .concat(this.state.visit.attachments)
          .concat(response.data);
        this.setState({
          isUploadingFiles: false,
          visit: {
            ...this.state.visit,
            attachments: updateAttachmentsState,
          },
        });
      },
      (err) => {
        console.log(`error during image upload: ${err}`);
        this.setState({ isUploadingFiles: false });
        this.props.showToast(
          Languages[this.props.selectedLanguageKey].strings.showToastMessages.uploadImageFailure,
          TOAST_TYPES.ERROR,
        );
      },
    );
  };

  handleRemoveVisitAttachment = (index) => {
    const updateAttachments = [].concat(this.state.visit.attachments);
    updateAttachments.splice(index, 1);
    this.setState({
      visit: {
        ...this.state.visit,
        attachments: updateAttachments,
      },
    });
  };

  // responses can be null, true or false
  getInitialMedicalResponses = (medicalHistory) => {
    const medicalHistoryResponses = {
      medications:
        medicalHistory.medications === null ? null : medicalHistory.medications.length > 0,
      medicalHistory:
        medicalHistory.medicalHistory === null ? null : medicalHistory.medicalHistory.length > 0,
      allergies: medicalHistory.allergies === null ? null : medicalHistory.allergies.length > 0,
      surgicalHistory:
        medicalHistory.surgicalHistory === null ? null : medicalHistory.surgicalHistory.length > 0,
    };
    return medicalHistoryResponses;
  };

  handleMedicalHistoryResponse = (key) => (value) => {
    const updateResponses = { ...this.state.medicalHistoryResponses };
    updateResponses[key] = value;

    let eventKey = '';
    switch (key) {
      case 'medications':
        value ? (eventKey = 'med_yes') : (eventKey = 'med_no');
        break;

      case 'medicalHistory':
        value ? (eventKey = 'medConditions_yes') : (eventKey = 'medConditions_no');
        break;

      case 'allergies':
        value ? (eventKey = 'allergies_yes') : (eventKey = 'allergies_no');
        break;

      case 'surgicalHistory':
        value ? (eventKey = 'surgeries_yes') : (eventKey = 'surgeries_no');
        break;

      default:
        break;
    }

    logEvent(eventKey, {
      patientId: this.state.profile.id,
      entryId: entranceId,
      accountId: this.props.profile.id,
    });

    this.setState({
      medicalHistoryResponses: updateResponses,
    });
    this.props.setDirtyForm();
  };

  handleMedicalSelectFieldChange = (key) => (value) => {
    const updateMedicalHistoryState = { ...this.state.medicalHistory };
    updateMedicalHistoryState[key] = value;
    this.setState({
      medicalHistory: updateMedicalHistoryState,
    });
    this.props.setDirtyForm();
  };

  handleInsuranceFieldChange = (field) => (update) => {
    // see if update is an event, if not it is a value already
    if (update && has(update.target, 'value')) {
      update.persist();
    }

    this.setState((prevState) => ({
      insuranceInfo: {
        ...prevState.insuranceInfo,
        [field]: update && has(update.target, 'value') ? update.target.value : update,
      },
    }));
  };

  // filter out former data that is being cleared by a 'no' response
  // only allow med history entries with a yes response
  getFilteredMedicalHistory = () => {
    const { medicalHistory, medicalHistoryResponses } = this.state;

    return {
      medications: medicalHistoryResponses.medications === false ? [] : medicalHistory.medications,
      allergies: medicalHistoryResponses.allergies === false ? [] : medicalHistory.allergies,
      medicalHistory:
        medicalHistoryResponses.medicalHistory === false ? [] : medicalHistory.medicalHistory,
      surgicalHistory:
        medicalHistoryResponses.surgicalHistory === false ? [] : medicalHistory.surgicalHistory,
    };
  };

  handleSaveMedicalHistory = async () => {
    const language = Languages[this.props.selectedLanguageKey].strings;
    const filteredMedicalHistory = this.getFilteredMedicalHistory();

    if (this.state.profile.accountType === accountTypes.CHILD) {
      const response = await this.props.updateDependentMedicalHistory(
        this.props.match.params.patientId,
        filteredMedicalHistory,
      );
      if (response.type === UPDATE_DEPENDENT_MEDICAL_HISTORY_SUCCESS) {
        this.props.showToast(language.showToastMessages.updateDependentMedicalHistorySuccess);
        this.setState({
          currentStepIndex: this.state.currentStepIndex + 1,
        });
      } else {
        this.props.showToast(
          language.showToastMessages.updateDependentMedicalHistoryFailure,
          TOAST_TYPES.ERROR,
          true,
          null,
          response.messages[0],
        );
      }
    } else if (this.state.profile.accountType === accountTypes.FACILITY_PATIENT) {
      const response = await this.props.updateFacilityPatientMedicalHistory(
        this.props.match.params.patientId,
        filteredMedicalHistory,
      );
      if (response.type === UPDATE_FAC_PATIENT_MEDICAL_HISTORY_SUCCESS) {
        this.props.showToast(language.showToastMessages.updateMedicalHistorySuccess);
        this.setState({
          currentStepIndex: this.state.currentStepIndex + 1,
        });
      } else {
        this.props.showToast(
          language.showToastMessages.updateMedicalHistoryFailure,
          TOAST_TYPES.ERROR,
          true,
          null,
          response.messages[0],
        );
      }
    } else {
      const response = await this.props.updateMedicalHistory(filteredMedicalHistory);
      if (response.type === UPDATE_MEDICAL_HISTORY_SUCCESS) {
        this.props.showToast(language.showToastMessages.updateMedicalHistorySuccess);
        this.setState({
          currentStepIndex: this.state.currentStepIndex + 1,
        });
      } else {
        this.props.showToast(
          language.showToastMessages.updateMedicalHistoryFailure,
          TOAST_TYPES.ERROR,
          true,
          null,
          response.messages[0],
        );
      }
    }
  };

  isMedicalHistoryValid = () => {
    const historyKeys = Object.keys(this.state.medicalHistory);
    const responseKeys = ['allergies', 'medicalHistory', 'surgicalHistory', 'medications'];

    const isMissingHistoryItems = historyKeys.some((key) => {
      if (this.state.medicalHistoryResponses[key] === true) {
        return isNil(this.state.medicalHistory[key]) || this.state.medicalHistory[key].length <= 0;
      }
      return false;
    });

    const isMissingResponse = responseKeys.some((key) => {
      const value = this.state.medicalHistoryResponses[key];
      return value !== true && value !== false;
    });

    return !isMissingHistoryItems && !isMissingResponse;
  };

  toggleLegalAccepted = (term) => {
    const updateLegalTerms = [].concat(this.state.selectedLegalTerms);

    const legalIndex = updateLegalTerms.findIndex((selectedTermID) => selectedTermID === term.id);

    if (legalIndex === -1) {
      updateLegalTerms.push(term.id);
    } else {
      updateLegalTerms.splice(legalIndex, 1);
    }

    this.setState({
      selectedLegalTerms: updateLegalTerms,
      legalIsValid: updateLegalTerms.length === this.props.legalTerms.length,
    });
  };

  // maintain a list of permissions with status
  handlePermissionChange = ({ type, status }) => {
    const updatePermissions = [].concat(this.state.permissions);
    const foundPermissionIndex = updatePermissions.findIndex((p) => p.type === type);
    if (foundPermissionIndex > -1) {
      updatePermissions.splice(foundPermissionIndex, 1, { type, status });
    } else {
      updatePermissions.push({ type, status });
    }

    logEvent('update_permissions', {
      patientId: this.state.profile.id,
      entryId: entranceId,
      accoutnId: this.props.profile.id,
    });

    this.setState({
      permissions: updatePermissions,
    });
  };

  isPermissionsValid() {
    const requiredLocationFields = ['addressLine1', 'addressCity', 'addressState', 'addressZip'];
    let isMobileNumberValid = !isEmpty(this.state.visit.mobileNumber);
    const isFacilitatedVisit = !isNil(this.props.selectedFacility);

    let hasAllRequiredFields = true;
    requiredLocationFields.forEach((field) => {
      if (isEmpty(this.state.visit.location[field].trim())) {
        hasAllRequiredFields = false;
      }
    });

    // allow no mobile number to be provided if specified in facility settings
    if (
      isFacilitatedVisit &&
      has(this.props.selectedFacility, 'skipPhone') &&
      this.props.selectedFacility.skipPhone === true
    ) {
      isMobileNumberValid = true;
    }

    // check permissions
    const hasAllRequiredPermissions = this.state.permissions.every(
      (p) => p.status === browserPermissionStatus.GRANTED,
    );

    return hasAllRequiredPermissions && hasAllRequiredFields && isMobileNumberValid;
  }

  handleVisitFieldChange = (field) => (update) => {
    const updateVisit = { ...this.state.visit };

    // see if update is an event, if not it is a value already
    updateVisit[field] = has(update.target, 'value') ? update.target.value : update;

    this.setState({
      visit: {
        ...this.state.visit,
        ...updateVisit,
      },
    });
  };

  debouncedGetPricing = debounce(
    () => {
      const { couponCode, groupCode, groupIdentifier } = this.state.visit;
      this.props.getPricing(couponCode, groupCode, groupCode !== '' ? groupIdentifier : '');
      this.props.setPricingCodes(couponCode, groupCode, groupIdentifier);
    },
    1000,
    {
      trailing: true,
    },
  );

  handlePaymentFieldChange = (field) => (event) => {
    const updateVisit = { ...this.state.visit };

    // if clearing employer code, also clear identifier
    if (field === 'groupCode' && event.target.value === '') {
      updateVisit.groupIdentifier = '';
    }

    updateVisit[field] = event.target.value.toUpperCase();

    this.setState(
      {
        visit: {
          ...this.state.visit,
          ...updateVisit,
        },
      },
      () => {
        this.debouncedGetPricing();
      },
    );

    if (field === 'couponCode') {
      logEvent('coupon_entered', {
        patientId: this.state.profile.id,
        entryId: entranceId,
        accountId: this.props.profile.id,
      });
    } else if (field === 'groupIdentifier') {
      logEvent('pg_entered', {
        patientId: this.state.profile.id,
        entryId: entranceId,
        accountId: this.props.profile.id,
      });
    }
  };

  // set errors object for each step
  // track field name and error
  handleValidationChange = (stepId) => (field) => (error) => {
    const { steps } = this.state;
    const allStepErrors = { ...this.state.stepErrors };

    const stepIndex = steps.findIndex((s) => s.id === stepId);
    const currentStepErrors = allStepErrors[stepIndex] ? allStepErrors[stepIndex] : [];

    // if field key is already present, overwrite existing error with new one. if no error, delete existing error
    // otherwise push new error
    const existingErrorIndex = currentStepErrors.findIndex((item) => item.field === field);

    if (existingErrorIndex > -1) {
      if (error) {
        currentStepErrors.splice(existingErrorIndex, 1, { field, error });
      } else {
        currentStepErrors.splice(existingErrorIndex, 1);
      }
    } else if (error) {
      currentStepErrors.push({ field, error });
    }

    if ((error && existingErrorIndex === -1) || !error) {
      allStepErrors[stepIndex] = currentStepErrors;

      this.setState({
        stepErrors: allStepErrors,
      });
    }
  };

  handleSaveProfile = () => {
    const { profile } = this.state;
    if (profile.accountType === accountTypes.ACCOUNT_HOLDER) {
      this.saveAccountHolderProfile();
    } else if (profile.accountType === accountTypes.CHILD) {
      this.saveDependentProfile();
    } else if (profile.accountType === accountTypes.FACILITY_PATIENT) {
      this.saveFacilityPatient();
    }
  };

  // update account profile and show a toast message if successful
  saveAccountHolderProfile = async () => {
    let updateProfileState = { ...this.state.profile };

    const deleteFields = [
      'accountType',
      'dependents',
      'email',
      'id',
      'incomplete',
      'weight',
      'currentVisit',
      'pharmacyPlaceId',
      'pharmacyDoseSpotId',
      'pricingData',
      'facilities',
      'password',
    ];

    // delete unneeded fields
    deleteFields.forEach((field) => delete updateProfileState[field]);

    // disallow null/undefined/blank properties
    updateProfileState = omitBy(updateProfileState, (v) => isEmpty(v) || isNil(v));

    const result = await this.props.updateProfile(updateProfileState);
    if (result.type === UPDATE_PROFILE_SUCCESS) {
      logEvent('profile_edit', {
        patientId: updateProfileState.id,
        entranceId,
        accountId: updateProfileState.id,
      });
      this.setState({
        currentStepIndex: this.state.currentStepIndex + 1,
      });
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateProfileFailure,
        TOAST_TYPES.ERROR,
      );
    }
  };

  saveDependentProfile = async () => {
    const dependent = { ...this.state.profile };
    const dependentId = dependent.id;

    if (dependent.addressLine2 === '' || dependent.addressLine2 === null)
      delete dependent.addressLine2;

    if (
      !lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(
        moment(dependent.dob).format(DATE_INPUT_DATE_FORMAT),
      )
    )
      delete dependent.weight;

    delete dependent.id;
    delete dependent.accountType;
    delete dependent.pharmacyPlaceId;
    delete dependent.pharmacyDoseSpotId;
    delete dependent.incomplete;

    const response = await this.props.updateDependent(dependentId, dependent);

    if (response.type === UPDATE_DEPENDENT_SUCCESS) {
      logEvent('profile_edit', {
        patientId: updateProfile.id,
        entranceId,
        accountId: this.props.profile.id,
      });
      this.setState({
        currentStepIndex: this.state.currentStepIndex + 1,
      });
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.updateDependentFailure,
        TOAST_TYPES.ERROR,
        true,
        null,
        response.messages[0],
      );
    }
  };

  saveFacilityPatient = async () => {
    const patient = { ...this.state.profile };
    const savePatientId = patient.id;

    if (patient.addressLine2 === '' || patient.addressLine2 === null) delete patient.addressLine2;

    if (
      !lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(
        moment(patient.dob).format(DATE_INPUT_DATE_FORMAT),
      )
    )
      delete patient.weight;

    const deleteFields = [
      'accountType',
      'id',
      'created',
      'updated',
      'populationId',
      'medications',
      'allergies',
      'medicalHistory',
      'surgicalHistory',
      'pharmacyPlaceId',
      'pharmacyDoseSpotId',
      'doseSpotPatientId',
    ];

    // delete unneeded fields
    deleteFields.forEach((field) => delete patient[field]);

    const response = await this.props.updateFacilityPatient(savePatientId, patient);

    if (response.type === UPDATE_FACILITY_PATIENT_SUCCESS) {
      logEvent('profile_edit', {
        patientId: savePatientId,
        entranceId,
        accountId: this.props.profile.id,
      });
      this.setState({
        currentStepIndex: this.state.currentStepIndex + 1,
      });
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages
          .updateFacilitatedPatientFailure,
        TOAST_TYPES.ERROR,
        true,
        null,
        response.messages[0],
      );
    }
  };

  // disable next step if there are step errors
  // or if a custom criterion is met (such as no payment info set)
  isNextDisabled = () => {
    const { stepErrors, currentStepIndex, steps } = this.state;

    let isNextDisabled = !isEmpty(stepErrors[currentStepIndex]);

    // custom logic
    if (!isNil(steps[currentStepIndex])) {
      if (steps[currentStepIndex].id === 'PAYMENT_INFO') {
        const isZeroPriceVisit =
          has(this.props.pricing, 'price.price') && this.props.pricing.price.price === 0;

        isNextDisabled =
          isEmpty(this.props.paymentMethods) && isNil(this.state.stripeToken) && !isZeroPriceVisit;
      }

      if (steps[currentStepIndex].id === 'TODAYS_VISIT') {
        isNextDisabled =
          this.state.isUploadingFiles || isNextDisabled || isEmpty(this.state.visit.chiefComplaint);
      }

      if (steps[currentStepIndex].id === 'CONFIRM_INFO') {
        const isFacilitatedVisit = !isNil(this.props.selectedFacility);
        const canSkipPharmacy =
          has(this.props.selectedFacility, 'skipPharmacy') &&
          this.props.selectedFacility.skipPharmacy === true;

        const isZeroPriceVisit =
          (has(this.props.pricing, 'price.price') && this.props.pricing.price.price === 0) ||
          isFacilitatedVisit;

        isNextDisabled =
          (isEmpty(this.props.paymentMethods) && !isZeroPriceVisit) ||
          (!canSkipPharmacy && isEmpty(this.state.selectedPharmacy));
      }

      if (steps[currentStepIndex].id === 'PHARMACY') {
        isNextDisabled =
          isNil(this.state.selectedPharmacy) && isNil(this.state.profile.pharmacyDoseSpotId);
      }

      if (steps[currentStepIndex].id === 'MED_HISTORY') {
        isNextDisabled = this.isMedicalHistoryValid() !== true;
      }

      if (steps[currentStepIndex].id === 'VISIT_TERMS') {
        if (isEmpty(this.props.legalTerms) || this.props.legalTerms.length === 0) {
          isNextDisabled = false;
        } else {
          isNextDisabled = this.state.legalIsValid !== true;
        }
      }

      if (steps[currentStepIndex].id === 'PERMISSIONS') {
        isNextDisabled = !this.isPermissionsValid() || isNextDisabled;
      }

      if (steps[currentStepIndex].id === 'INSURANCE_INFO') {
        isNextDisabled = this.state.isUploadingFiles || isNextDisabled;
      }
    }

    return isNextDisabled;
  };

  isCurrentStepLoading = () => {
    if (!isNil(this.state.steps[this.state.currentStepIndex])) {
      const { id } = this.state.steps[this.state.currentStepIndex];
      switch (id) {
        case 'PATIENT_INFO':
          return this.props.isLoadingProfile;

        case 'MED_HISTORY':
          return this.props.isLoadingMedicalHistory || this.props.isSavingMedicalHistory;

        case 'VISIT_TERMS':
          return this.props.isLoadingVisit;

        case 'PAYMENT_INFO':
          return this.props.isSavingPaymentInfo;

        case 'PERMISSIONS':
          return this.props.isLoadingVisit;

        default:
          return false;
      }
    }
    return false;
  };

  startVisit = async () => {
    // dont send address line 2 if not complete in location
    const location = { ...this.state.visit.location };
    if (isEmpty(location.addressLine2)) delete location.addressLine2;

    const payload = {
      patientId: this.state.profile.id,
      chiefComplaint: this.state.visit.chiefComplaint,
      price: this.props.pricing.price.price,
      legalConsent: this.state.selectedLegalTerms,
      mobileNumber: this.state.visit.mobileNumber,
      location,
    };

    if (!isEmpty(this.state.visit.attachments)) {
      payload.attachments = this.state.visit.attachments.map((a) => a.id);
    }

    if (!isEmpty(this.props.pricingCodes.coupon)) {
      payload.couponCode = this.props.pricingCodes.coupon;
    }

    if (!isEmpty(this.props.pricingCodes.group)) {
      payload.groupCode = this.props.pricingCodes.group;
    }

    if (!isEmpty(this.props.pricingCodes.identifier)) {
      payload.groupIdentifier = this.props.pricingCodes.identifier;
    }

    // customizations for facilitated visit
    if (!isNil(this.props.selectedFacility)) {
      payload.facilityId = this.props.selectedFacility.id;

      // if skipPhone is configured on a facility, omit from request
      if (
        has(this.props.selectedFacility, 'skipPhone') &&
        this.props.selectedFacility.skipPhone === true
      ) {
        delete payload.mobileNumber;
      }
    }

    console.log('STARTING VISIT: ', payload);
    const response = await this.props.startVisit(payload);

    console.log('START VISIT RESULT: ', response);
    if (response.type === START_VISIT_SUCCESS) {
      await this.props.getCurrentVisit();
      this.props.history.push('/waitingRoom');
    } else {
      this.props.showToast(
        Languages[this.props.selectedLanguageKey].strings.showToastMessages.startVisitFailure,
        TOAST_TYPES.ERROR,
        true,
        null,
        response.messages[0],
      );
    }
  };

  render() {
    const { classes, entrance, selectedLanguageKey } = this.props;
    const { steps, currentStepIndex } = this.state;
    const marketingLegalImage = getMarketingContent(
      entrance,
      MARKETING_CONTENT_TYPES.DESKTOP_VISIT_LEGAL,
    );

    let nextButtonText;
    let currentStep;

    if (steps.length !== 0) {
      nextButtonText = !isNil(steps[currentStepIndex].nextText)
        ? steps[currentStepIndex].nextText
        : 'NEXT';
      currentStep = steps[currentStepIndex];
    }

    if (this.isCurrentStepLoading() || steps.length === 0) return <LoadingOverlay />;

    return (
      <div className={classes.container}>
        {steps[currentStepIndex].id === 'VISIT_TERMS' && (
          <img
            className={classes.image}
            src={!isEmpty(marketingLegalImage) ? marketingLegalImage[0].imageUrl : legalImage}
            alt="Legal Marketing Content"
          />
        )}
        <div className={classes.content}>
          <div
            className={classNames(classes.stepperContent, {
              [classes.stepperHeight]: steps[currentStepIndex].id !== 'VISIT_TERMS',
            })}
          >
            {currentStep && currentStep.heading && (
              <Typography className={classes.stepHeading} variant="h5">
                {currentStep.heading}
              </Typography>
            )}
            {currentStep && currentStep.subHeading && (
              <Typography className={classes.stepSubHeading} variant="h6">
                {currentStep.subHeading}
              </Typography>
            )}
            {currentStep && currentStep.getContent()}
          </div>
        </div>
        <div className={classes.stepperActionsContainer}>
          {!isNil(this.state.selectedPharmacy) && steps[currentStepIndex].id === 'PHARMACY' && (
            <Typography variant="subtitle1" color="primary">
              {`${Languages[selectedLanguageKey].strings.pharmacySelected} ${this.state.selectedPharmacy.StoreName}`}
            </Typography>
          )}
          <div className={classes.stepperActions}>
            <Button
              onClick={this.handleBack}
              className={classes.actionButton}
              variant="outlined"
              color="primary"
            >
              {currentStepIndex === 0
                ? Languages[selectedLanguageKey].strings.buttonText.cancel
                : Languages[selectedLanguageKey].strings.buttonText.back}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={has(currentStep, 'nextAction') ? currentStep.nextAction : this.handleNext}
              className={classes.actionButton}
              disabled={this.isNextDisabled()}
            >
              {nextButtonText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  content: {
    background: theme.palette.primary.background,
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.background,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  stepperContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem auto 0',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepperActionsContainer: {
    background: theme.palette.primary.background,
    bottom: 0,
    paddingTop: '1rem',
    position: 'absolute',
    textAlign: 'center',
    width: 800,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepperActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButton: {
    fontSize: 14,
    margin: '0 1rem 1rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: '130px', // This is the height of the image when it hits this breakpoint
    },
    maxHeight: UIVars.headerGraphic.height,
  },
  stepperHeight: {
    height: 'calc(100vh - 88px)',
  },
  stepHeading: {
    color: theme.palette.primary.darkGray,
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  stepSubHeading: {
    color: theme.palette.primary.darkGray,
    margin: '1rem',
  },
});

VisitContainer.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  classes: PropTypes.object.isRequired,
  entrance: PropTypes.object.isRequired,
  getFacilityPatientMedicalHistory: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoadingInsuranceInfo: PropTypes.bool.isRequired,
  isLoadingMedicalHistory: PropTypes.bool.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  isLoadingVisit: PropTypes.bool.isRequired,
  isSavingMedicalHistory: PropTypes.bool.isRequired,
  isSavingPaymentInfo: PropTypes.bool.isRequired,
  legalTerms: PropTypes.array,
  paymentMethods: PropTypes.array,
  pharmacy: PropTypes.object,
  pricing: PropTypes.object,
  pricingCodes: PropTypes.object,
  profile: PropTypes.object,
  selectedFacility: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,

  clearPharmacy: PropTypes.func.isRequired,
  getCurrentPosition: PropTypes.func.isRequired,
  getCurrentVisit: PropTypes.func.isRequired,
  getDependentMedicalHistory: PropTypes.func.isRequired,
  getInsuranceInfo: PropTypes.func.isRequired,
  getMedicalHistory: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  getPharmacyDetails: PropTypes.func.isRequired,
  getPreviousVisits: PropTypes.func.isRequired,
  getPricing: PropTypes.func.isRequired,
  resetPharmacies: PropTypes.func.isRequired,
  saveInsuranceInfo: PropTypes.func.isRequired,
  savePaymentMethod: PropTypes.func.isRequired,
  savePharmacy: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  setPricingCodes: PropTypes.func.isRequired,
  setSelectedFacility: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  startVisit: PropTypes.func.isRequired,
  updateDependent: PropTypes.func.isRequired,
  updateDependentMedicalHistory: PropTypes.func.isRequired,
  updateFacilityPatient: PropTypes.func.isRequired,
  updateFacilityPatientMedicalHistory: PropTypes.func.isRequired,
  updateMedicalHistory: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  clearPatients: PropTypes.func.isRequired,
  setFacilityPatientSearchText: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    entrance: state.entrance.entrance,
    insuranceInfo: state.profile.insuranceInfo,
    isLoadingInsuranceInfo: state.profile.isLoadingInsuranceInfo,
    isLoadingMedicalHistory: state.profile.isLoadingMedicalHistory,
    isLoadingProfile: state.profile.isLoadingProfile,
    isLoadingVisit: state.visit.isLoading,
    isSavingMedicalHistory: state.visit.isSavingMedicalHistory,
    isSavingPaymentInfo: state.profile.isSavingPaymentInfo,
    legalTerms: state.legal.legalTerms,
    paymentMethods: state.profile.paymentMethods,
    pharmacy: state.visit.pharmacy,
    pricing: state.visit.pricing,
    pricingCodes: state.visit.pricingCodes,
    profile: state.profile.profile,
    selectedFacility: state.facility.selectedFacility,
    selectedLanguageKey: state.language.selectedLanguageKey,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, {
    clearPatients,
    clearPharmacy,
    getCurrentPosition,
    getCurrentVisit,
    getDependentMedicalHistory,
    getFacilityPatientMedicalHistory,
    getInsuranceInfo,
    getMedicalHistory,
    getPaymentMethods,
    getPharmacyDetails,
    getPreviousVisits,
    getPricing,
    getProfile,
    resetPharmacies,
    saveInsuranceInfo,
    savePaymentMethod,
    savePharmacy,
    setDirtyForm,
    setPageTitle,
    setPricingCodes,
    setSelectedFacility,
    setFacilityPatientSearchText,
    showToast,
    startVisit,
    updateDependent,
    updateDependentMedicalHistory,
    updateFacilityPatient,
    updateFacilityPatientMedicalHistory,
    updateMedicalHistory,
    updateProfile,
  }),
)(VisitContainer);
