import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // FormControl,
  // InputLabel,
  // Select,
  // OutlinedInput,
  // MenuItem,
  Paper,
  withStyles,
  // FormHelperText
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import classnames from 'classnames';
import { genderTypes, getLongGender } from '../../../types/genderTypes';
import TextFieldValidator from '../../../common/inputValidators/textFieldValidator.component';
import DateFieldValidator from '../../../common/inputValidators/dateFieldValidator.component';
import {
  required,
  lessThanEqualTo18YearsAgo,
  greaterThan18YearsAgo,
  lessThan12YearsAgo,
  nonZeroInteger,
  charLimit,
  validCharacters,
  validZip,
  DATE_INPUT_DATE_FORMAT,
  DATE_FIELD_MASK,
  TEXT_INPUT_CHAR_LIMIT,
} from '../../../utilities/fieldValidation';
import accountTypes from '../../../types/accountTypes';
import GooglePlacesTextValidator from '../../../common/inputValidators/googlePlacesTextValidator.component';
import MaskedTextValidator from '../../../common/inputValidators/maskedTextValidator.component';
import SelectInputValidator from '../../../common/inputValidators/selectInputValidator.component';
import { getFormattedWeight } from '../../../utilities/dataUtils';
import { getUSStateOptions } from '../../../utilities/provinceUtils';
import Languages from '../../language/languages';

const truncateText = (value, limit = TEXT_INPUT_CHAR_LIMIT) => {
  return value.substring(0, limit - 1);
};

const styles = theme => ({
  formContainer: {
    margin: '0 1rem 6rem',
    padding: '1rem',
  },
  formControl: {
    width: '100%',
  },
  autocomplete: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
});

const genderOptions = [
  {
    value: genderTypes.MALE,
    label: getLongGender(genderTypes.MALE),
  },
  {
    value: genderTypes.FEMALE,
    label: getLongGender(genderTypes.FEMALE),
  },
  {
    value: genderTypes.ASK,
    label: getLongGender(genderTypes.ASK),
  },
];

class PatientProfileForm extends Component {
  handleAddressSelection = selection => {
    // propagate selection to address1 and other fields
    const addressLine1 = `${selection.street_number ? `${selection.street_number} ` : ''}${
      selection.route ? selection.route : ''
    }`;
    this.props.handleChange('addressLine1')(truncateText(addressLine1));

    // clear address line 2 - not integrated with autocomplete
    this.props.handleChange('addressLine2')('');

    const addressCity = `${selection.locality ? selection.locality : ''}`;
    this.props.handleChange('addressCity')(truncateText(addressCity));

    const addressState = `${
      selection.administrative_area_level_1 ? selection.administrative_area_level_1 : ''
    }`;
    this.props.handleChange('addressState')(truncateText(addressState));

    const addressZip = `${selection.postal_code ? selection.postal_code : ''}`;
    this.props.handleChange('addressZip')(truncateText(addressZip));
  };

  render() {
    const {
      classes,
      handleChange,
      profile,
      handleValidationChange,
      forceValidation,
      selectedLanguageKey,
      selectedFacility,
    } = this.props;

    const language = Languages[selectedLanguageKey].strings;

    const dobValidators = [required];
    if (profile.accountType === accountTypes.ACCOUNT_HOLDER) {
      dobValidators.push(greaterThan18YearsAgo(DATE_INPUT_DATE_FORMAT));
    } else if (profile.accountType === accountTypes.CHILD) {
      dobValidators.push(lessThanEqualTo18YearsAgo(DATE_INPUT_DATE_FORMAT));
    }

    const weight = getFormattedWeight(profile.weight);

    // configurable per facility
    // don't show address if skipping address entry
    const isRequireAddress = isNil(selectedFacility) || selectedFacility.skipAddress === false;

    // determine whether weight field is needed based on dob
    const hasDob = !isEmpty(profile.dob);
    let isShowWeightField = false;
    if (hasDob) {
      const lessThan12YearsOld =
        lessThan12YearsAgo(DATE_INPUT_DATE_FORMAT)(
          moment(profile.dob).format(DATE_INPUT_DATE_FORMAT)
        ) === true;
      if (lessThan12YearsOld) {
        isShowWeightField = true;
      }
    }

    return (
      <Paper className={classes.formContainer}>
        <TextFieldValidator
          label={language.inputLabels.firstName}
          className={classes.formControl}
          value={profile.firstName ? profile.firstName : ''}
          onChange={handleChange('firstName')}
          validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
          margin="normal"
          variant="outlined"
          onValidationChange={handleValidationChange('firstName')}
          forceValidation={forceValidation}
          required
        />

        <TextFieldValidator
          label={language.inputLabels.lastName}
          className={classes.formControl}
          value={profile.lastName ? profile.lastName : ''}
          onChange={handleChange('lastName')}
          validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
          margin="normal"
          variant="outlined"
          onValidationChange={handleValidationChange('lastName')}
          forceValidation={forceValidation}
          required
        />

        <DateFieldValidator
          className={classes.formControl}
          style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
          keyboard
          clearable
          maxDate={moment().format(DATE_INPUT_DATE_FORMAT)}
          value={profile.dob ? moment(profile.dob).format(DATE_INPUT_DATE_FORMAT) : ''}
          format={DATE_INPUT_DATE_FORMAT}
          mask={DATE_FIELD_MASK}
          validators={dobValidators}
          handleDateChange={handleChange('dob')}
          label={language.inputLabels.dateOfBirth}
          onValidationChange={handleValidationChange('dob')}
          forceValidation={forceValidation}
          openTo="year"
          required
        />

        {isShowWeightField && (
          <MaskedTextValidator
            className={classes.formControl}
            style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
            value={!isNil(weight) ? weight.toString() : ''}
            onChange={handleChange('weight')}
            label={language.inputLabels.weight}
            onValidationChange={handleValidationChange('weight')}
            variant="outlined"
            validators={[required, nonZeroInteger]}
            mask="999"
            maskPostfix=" lbs."
            forceValidation
            required
          />
        )}

        <SelectInputValidator
          label={language.inputLabels.gender}
          className={classes.formControl}
          value={!isEmpty(profile.gender) ? profile.gender : ''}
          onChange={handleChange('gender')}
          validators={[required]}
          margin="normal"
          variant="outlined"
          onValidationChange={handleValidationChange('gender')}
          options={genderOptions}
          forceValidation={forceValidation}
          required
          labelWidth={75}
        />

        {/* <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
          required
        >
          <InputLabel htmlFor="gender-select">{language.inputLabels.gender}</InputLabel>
          <Select
            value={profile.gender ? profile.gender : ''}
            onChange={evt => {
              handleChange('gender')(evt);
              if (isEmpty(evt.target.value)) {
                handleValidationChange('gender')(language.genderRequired);
              } else {
                handleValidationChange('gender')(null);
              }
            }}
            input={<OutlinedInput name="gender" labelWidth={70} id="gender-select" />}
            error={isEmpty(profile.gender)}
          >
            <MenuItem value="" />
            <MenuItem value={genderTypes.MALE}>{getLongGender(genderTypes.MALE)}</MenuItem>
            <MenuItem value={genderTypes.FEMALE}>{getLongGender(genderTypes.FEMALE)}</MenuItem>
            <MenuItem value={genderTypes.ASK}>{getLongGender(genderTypes.ASK)}</MenuItem>
          </Select>
          {isEmpty(profile.gender) && <FormHelperText>{language.genderRequired}</FormHelperText>}
        </FormControl> */}

        {isRequireAddress && (
          <>
            <GooglePlacesTextValidator
              onChange={handleChange('addressLine1')}
              validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
              label={language.inputLabels.addressLineOne}
              inputClassName={classnames([classes.formControl, classes.autocomplete])}
              value={profile.addressLine1 ? profile.addressLine1 : ''}
              onSelect={this.handleAddressSelection}
              forceValidation={forceValidation}
              onValidationChange={handleValidationChange('addressLine1')}
              required
            />

            <TextFieldValidator
              label={language.inputLabels.addressLineTwo}
              className={classes.formControl}
              value={profile.addressLine2 ? profile.addressLine2 : ''}
              onChange={handleChange('addressLine2')}
              validators={[charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
              margin="normal"
              variant="outlined"
              onValidationChange={handleValidationChange('addressLine2')}
              forceValidation={forceValidation}
            />

            <TextFieldValidator
              label={language.inputLabels.city}
              className={classes.formControl}
              value={profile.addressCity ? profile.addressCity : ''}
              onChange={handleChange('addressCity')}
              validators={[required, charLimit(TEXT_INPUT_CHAR_LIMIT), validCharacters]}
              margin="normal"
              variant="outlined"
              onValidationChange={handleValidationChange('addressCity')}
              forceValidation={forceValidation}
              required
            />

            <SelectInputValidator
              label={language.inputLabels.state}
              className={classes.formControl}
              value={!isEmpty(profile.addressState) ? profile.addressState : ''}
              onChange={handleChange('addressState')}
              validators={[required]}
              margin="normal"
              variant="outlined"
              onValidationChange={handleValidationChange('addressState')}
              options={getUSStateOptions()}
              forceValidation={forceValidation}
              required
              labelWidth={50}
            />

            <MaskedTextValidator
              label={language.inputLabels.zipCode}
              className={classes.formControl}
              value={profile.addressZip ? profile.addressZip : ''}
              onChange={handleChange('addressZip')}
              validators={[required, validZip]}
              margin="normal"
              variant="outlined"
              mask="99999"
              onValidationChange={handleValidationChange('addressZip')}
              forceValidation={forceValidation}
              required
            />
          </>
        )}
      </Paper>
    );
  }
}

PatientProfileForm.defaultProps = {
  forceValidation: false,
};

PatientProfileForm.propTypes = {
  classes: PropTypes.object.isRequired,
  forceValidation: PropTypes.bool,
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleValidationChange: PropTypes.func,
  profile: PropTypes.object,
  selectedLanguageKey: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  selectedFacility: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    selectedFacility: state.facility.selectedFacility,
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {})(PatientProfileForm)
);
